<template>
  <el-form
    :inline="true"
    :model="data"
    style="width: 96%; margin-left: 2%;margin-top: 10px;"
    v-loading.fullscreen.lock="fullscreenLoading"
    element-loading-text="正在火速加载数据..."
  >
    <el-form-item label="指标日期">
      <el-date-picker v-model="searchFilter.targetDay" type="date" placeholder="选择日期" value-format="YYYY-MM-DD">
      </el-date-picker>
    </el-form-item>

    <el-form-item label="门店区域">
      <el-cascader
        ref="casRef"
        v-model="searchFilter.regionCodeList"
        :options="areaLists"
        clearable
        filterable
        collapse-tags
        placeholder="请选择门店区域"
        collapse-tags-tooltip
        @change="changeArea()"
        :show-all-levels="false"
        :props="areaProps"
      ></el-cascader>
    </el-form-item>
    <el-form-item label="门店">
      <el-select-v2
        v-model="searchFilter.storeCodeList"
        :options="storeLists"
        filterable
        placeholder="请选择门店"
        class="search-select userSelect"
        multiple
        collapse-tags
        style="width: 300px;"
        collapse-tags-tooltip
      />
    </el-form-item>

    <el-form-item>
      <el-button @click="searchLists" type="primary" v-if="authMenus.query">
        查询
      </el-button>
      <el-button @click="reseatFilter" v-if="authMenus.query">
        重置
      </el-button>
      <el-button @click="handleEdit" v-if="authMenus.update">
        编辑日权重
      </el-button>
      <el-button @click="reCalculateVisiable = true" v-if="authMenus.recalculate">
        重新计算
      </el-button>
      <el-button @click="exportData" v-if="authMenus.export">
        导出
      </el-button>
    </el-form-item>
  </el-form>
  <p style="color: red;margin-left: 2%;margin-bottom: 10px;">
    每天9:00自动更新业绩数据
  </p>
  <el-table
    :data="tableData"
    border
    style="width: 96%; margin-left: 2%;"
    :emptyText="emptyTableText"
    ref="tableRef"
    @sort-change="handleSortChange"
  >
    <el-table-column align="center" width="200" prop="targetDayOrWeeks" label="指标日期"> </el-table-column>
    <el-table-column align="center" sortable="custom" width="200" prop="dayRevenueRanking" label="日营收完成率排名">
    </el-table-column>

    <el-table-column align="center" width="100" prop="storeCode" label="门店编号"> </el-table-column>
    <el-table-column align="center" width="200" prop="storeName" label="门店名称"> </el-table-column>
    <el-table-column align="center" width="200" prop="organizationName" label="所属大区"> </el-table-column>
    <el-table-column align="center" prop="weight" width="200" label="日权重/当月总权重"> </el-table-column>
    <el-table-column
      align="center"
      sortable="custom"
      width="200"
      prop="dayRevenueIndicator"
      label="当日营收指标（万元）"
    >
    </el-table-column>
    <el-table-column align="center" width="200" sortable="custom" prop="settleAmount" label="日营收(结算口径)">
    </el-table-column>
    <el-table-column align="center" sortable="custom" width="200" prop="revenueRanking" label="日营收排名">
    </el-table-column>
    <el-table-column align="center" sortable="custom" width="200" prop="dayCompletionRate" label="日营收完成率">
    </el-table-column>
    <el-table-column align="center" sortable="custom" width="200" prop="currentStoredValue" label="当日充值（万元）">
    </el-table-column>
    <el-table-column align="center" sortable="custom" width="200" prop="currentStoredValueRanking" label="充值排名">
    </el-table-column>
    <el-table-column align="center" width="220" prop="createTime" label="更新时间" />
    <el-table-column align="center" label="操作" width="300" fixed="right">
      <template v-slot="{ row }">
        <el-button type="text" @click="queryTarget(row)" v-if="row.targetDayOrWeeks != '合计'">月权重详情</el-button>
        <el-button type="text" @click="deleteTarget(row)" v-if="row.targetDayOrWeeks != '合计'">删除指标</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-model:current-page="searchFilter.pageNum"
    v-model:page-size="searchFilter.pageSize"
    :small="small"
    :disabled="disabled"
    :background="background"
    layout="total,
  sizes, prev, pager, next, jumper"
    :total="searchFilter.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />

  <!--  编辑日指标-->
  <el-dialog
    :title="isEdit ? '编辑日权重' : '月权重详情'"
    width="70%"
    v-model="updateDialogVisible"
    @close="handleCloseModel"
  >
    <el-form label-width="80px" :rules="updateRuleForm" ref="updateFormRef" :model="updateRowMsg">
      <el-form-item label="指标月份" prop="targetMonth">
        <el-date-picker
          v-model="updateRowMsg.targetMonth"
          type="month"
          placeholder="选择日期"
          value-format="YYYY-MM"
          :disabled="!isEdit"
          @change="getOneMonthAllDayTimeAndWeek(updateRowMsg.targetMonth)"
        >
        </el-date-picker>
      </el-form-item>
      <!-- <el-form-item label="指定门店可用">
        <el-switch v-model="updateRowMsg.isFixedStore" :active-value="1" inactive-value="0"></el-switch>
      </el-form-item> -->
      <el-form-item label="权重门店" v-if="!isEdit" class="is-required">
        门店权重
      </el-form-item>
      <!-- <el-form-item label="适用门店" label-width="120px" prop="storeCodeList" v-if="isEdit">
        <FilterShop :storeData="updateRowMsg.storeCodeList" @changeStore="changeStore" />
      </el-form-item> -->
      <div style="display: flex; flex-wrap: wrap;">
        <template v-for="item in dayWeekList" :key="item.label">
          <el-form-item :label="item.label" required style="margin-right:10px">
            <el-input-number
              :disabled="!isEdit"
              v-model="item.value"
              :precision="0"
              :min="0"
              :controls="false"
              placeholder="请输入系数"
            />
          </el-form-item>
        </template>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="updateDialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="updateTarget(updateFormRef)" :disabled="!isEdit">确 定</el-button>
    </span>
  </el-dialog>

  <!--  重新计算-->
  <el-dialog
    title="重新计算"
    v-model="reCalculateVisiable"
    width="50%"
    class="dialog-form"
    :before-close="handleClose(reCalculateVisiable)"
  >
    <el-form label-width="80px" class="dialog-form" :model="reCalculateData">
      <el-form-item label="指标月份">
        <el-date-picker
          v-model="reCalculateData.reCalculateMonth"
          type="month"
          placeholder="选择日期"
          value-format="YYYY-MM"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="reCalculateVisiable = false">取 消</el-button>
      <el-button type="primary" @click="reCalculate">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script setup>
import commonApi from '@/api'
import FilterShop from '../../component/filter-shop.vue'
import moment from 'moment'
import { onMounted, reactive, ref, nextTick, h } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import API from '../../service/index.js'
import operationAuth from '@/lin/util/operationAuth.js'
import { useRoute } from 'vue-router'
import clearSelect from '@/lin/util/clearSelect2.js'
import { Search } from '@element-plus/icons-vue'
import { post } from '@/lin/plugin/axios'
import { createMoveBall } from '@/utils/createMoveBall.js'

const casRef = ref()
const changeArea = () => {
  searchFilter.storeCodeList = []
  searchFilter.areaCode = searchFilter.regionCodeList.map(item => item[item.length - 1])
  nextTick(() => {
    clearSelect('userSelect')
    getShops()
  })
}

//搜索条件
const searchFilter = reactive({
  regionCodeList: [],
  storeCodeList: [],
  areaCode: [],
  pageNum: 1,
  pageSize: 10,
  total: 0,
  targetDay: moment(new Date())
    .subtract(1, 'day')
    .format('YYYY-MM-DD'),
  sortDirection: '',
  sortField: '',
})

const tableRef = ref(null)

//重制搜索条件
const reseatFilter = () => {
  searchFilter.storeCodeList = []
  searchFilter.regionCodeList = []
  searchFilter.areaCode = []
  searchFilter.targetDay = moment(new Date())
    .subtract(1, 'day')
    .format('YYYY-MM-DD')
  searchFilter.pageNum = 1
  searchFilter.pageSize = 10
  searchFilter.sortDirection = ''
  ;(searchFilter.sortField = ''), tableRef.value.clearSort()
  nextTick(() => {
    clearSelect('userSelect')
  })
  queryLists()
}

const fullscreenLoading = ref(false)
const updateDialogVisible = ref(false) //修改弹出框
const updateRowMsg = ref({ storeCodeList: [], targetMonth: moment(new Date()).format('YYYY-MM') }) //需要操作的某一行数据
const areaLists = ref([]) //区域
const storeLists = ref([]) //门店

//下拉列表属性
const areaProps = reactive({
  label: 'name',
  value: 'code',
  children: 'child',
  checkStrictly: true,
  multiple: true,
})

const isEdit = ref(false)
//编辑日权重
const queryTarget = async data => {
  updateDialogVisible.value = true
  updateRowMsg.value = {
    storeCodeList: [],
    targetMonth:
      data.targetDayOrWeeks.split('(')[0].split('-')[0] + '-' + data.targetDayOrWeeks.split('(')[0].split('-')[1],
  } //需要操作的某一行数据
  await getOneMonthAllDayTimeAndWeek(updateRowMsg.value.targetMonth)
  let res = await API.queryStoreDailyWeight({
    targetMonth:
      data.targetDayOrWeeks.split('(')[0].split('-')[0] + '-' + data.targetDayOrWeeks.split('(')[0].split('-')[1],
    storeCode: data.storeCode,
  })
  if (res.code == 200) {
    dayWeekList.value = dayWeekList.value.map(a => {
      let b = res.data.find(c => c.targetDay == a.dayTime)
      a.value = b.value
      return a
    })
    isEdit.value = false
  } else {
    ElMessage.error(res.msg)
  }
  nextTick(() => {})
}

const handleCloseModel = () => {
  updateFormRef.value.clearValidate()
}
/**
 * 删除某一行数据
 * @param {object} row
 */
const deleteTarget = async row => {
  const user = JSON.parse(localStorage.getItem('vuex'))
  fullscreenLoading.value = true
  let res = await API.deleteStoreDailyWeight({
    targetDay: row.targetDayOrWeeks.split('(')[0],
    storeCode: row.storeCode,
    storeName: row.storeName,
    updateUser: user.user.userInfo.userSaasUserVO.userName,
    targetWeeks: row.targetDayOrWeeks.split('(')[1].split(')')[0],
    dayWeight: row.weight,
    targetMonth:
      row.targetDayOrWeeks.split('(')[0].split('-')[0] + '-' + row.targetDayOrWeeks.split('(')[0].split('-')[1],
  }).finally(err => (fullscreenLoading.value = true))
  if (res.code == 200) {
    ElMessage.success('操作成功')
    queryLists()
  } else {
    ElMessage.error(res.msg)
  }
}
/**
 * 关闭弹出框
 */
const handleClose = target => {
  console.log('🚀 ~ handleClose ~ target:', target)
  target = false
}

const handleEdit = () => {
  updateRowMsg.value = { storeCodeList: [], targetMonth: moment(new Date()).format('YYYY-MM') } //需要操作的某一行数据
  getOneMonthAllDayTimeAndWeek(updateRowMsg.value.targetMonth)
  updateDialogVisible.value = true
  isEdit.value = true
}

const searchLists = () => {
  searchFilter.pageNum = 1
  queryLists()
}

const handleSortChange = ({ column, prop, order }) => {
  searchFilter.sortDirection = order === 'ascending' ? 'asc' : 'desc'
  searchFilter.sortField = prop
  queryLists()
}
const emptyTableText = ref('请先设置查询条件，再点击查询数据')
/**
 * 获取表单数据
 */
const tableData = ref([])
const queryLists = async () => {
  if (authMenus.value.query) {
    let param = {
      regions: searchFilter.areaCode,
      stores: searchFilter.storeCodeList,
      targetDay: searchFilter.targetDay,
      pageNum: searchFilter.pageNum,
      pageSize: searchFilter.pageSize,
      sortDirection: searchFilter.sortDirection,
      sortField: searchFilter.sortField,
    }
    let params = generateParam(param)
    //占坑
    tableData.value = [{ name: '合计' }]

    getTableData(params, 'STORE_REVENUE_DAILY_REPORT')

    if (params.pageNum > 1) {
      return tableData.value.splice(0, 1, totalRowData.value)
    }
    getTotalRowRequest(params, 'STORE_REVENUE_DAILY_REPORT')
  } else {
    ElMessage.warning('抱歉，你没有查看权限')
  }
}
/**
 * @description: 生成请求参数
 * @param {*} type
 * @return {*}
 */
const generateParam = data => {
  let params = {
    pageNum: data.pageNum,
    pageSize: data.pageSize,
  }
  let model = _.cloneDeep(data)
  delete model.pageSize
  delete model.pageNum
  params.criteria = JSON.stringify(model)
  return params
}
const totalRowData = ref({})

// 获取报表数据
const getTableData = async (params, type) => {
  fullscreenLoading.value = true
  let res = await post(`/shadow/report/details?type=${type}`, params).finally(() => {
    fullscreenLoading.value = false
  })
  if (res.code == 200) {
    tableData.value.push(...(res.data.items ?? []))
    searchFilter.total = res.data.total
    emptyTableText.value = tableData.value.length == 0 ? '暂无数据' : ''
  } else {
    tableData.value = []
    searchFilter.total = 0
  }
}
/**
 * @description: 获取合计请求数据
 * @return {*}
 */
const getTotalRowRequest = async (params, type) => {
  let res = await post(`/shadow/report/summary?type=${type}`, params)
  if (res.code == 200) {
    totalRowData.value = res.data ?? {}
    tableData.value.splice(0, 1, totalRowData.value)
  } else {
    tableData.value.splice(0, 1)
  }
}

const authMenus = ref({})
const route = useRoute()
onMounted(async () => {
  operationAuth(route, authMenus.value)
  getAreas()
  getShops()
})

const oldDayWeight = ref([])
const queryCountryDayWeightList = async month => {
  let res = await API.queryCountryDayWeightList({ targetMonth: month })
  if (res.code === '200') {
    oldDayWeight.value = res.data
  } else {
    ElMessage.error(res.msg)
  }
}

//获取区域数据
const getAreas = () => {
  //获取门店区域
  const getTreeData = data => {
    for (var i = 0; i < data.length; i++) {
      if (data[i].children.length < 1) {
        // children若为空数组，则将children设为undefined
        data[i].children = undefined
      } else {
        // children若不为空数组，则继续 递归调用 本方法
        getTreeData(data[i].children)
      }
    }
    return data
  }
  commonApi.getNewArea().then(res => {
    areaLists.value = getTreeData(res.data)
  })
}
const getShops = () => {
  let params = {}
  if (searchFilter.areaCode.length > 0) {
    params.withInOrgans = searchFilter.areaCode
  }
  commonApi.getNewShop(params).then(res => {
    if (res.code === '200') {
      storeLists.value = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const dayWeekList = ref([]) //存储时间
/**
 * 获取某月的所有天和星期
 */
const getOneMonthAllDayTimeAndWeek = async month => {
  if (!month) return
  await queryCountryDayWeightList(month)

  dayWeekList.value = []
  //获取星期
  if (oldDayWeight.value.length == 0) {
    const getWeek = date => {
      // 参数时间戳
      let week = moment(date).day()
      switch (week) {
        case 1:
          return '周一'
        case 2:
          return '周二'
        case 3:
          return '周三'
        case 4:
          return '周四'
        case 5:
          return '周五'
        case 6:
          return '周六'
        case 0:
          return '周日'
      }
    }

    let firstDay = moment(month)
      .startOf('month')
      .format('YYYY-MM-DD')
    let lastDay = moment(month)
      .endOf('month')
      .format('YYYY-MM-DD')
    for (
      let i = firstDay;
      i <= lastDay;
      i = moment(i)
        .add(1, 'days')
        .format('YYYY-MM-DD')
    ) {
      dayWeekList.value.push({
        dayTime: i,
        week: getWeek(i),
        label: `${moment(i)
          .format('MM-DD')
          .split('-')
          .join('月')}日(${getWeek(i)})`,
      })
    }
    return
  }

  oldDayWeight.value.forEach(a => {
    dayWeekList.value.push({
      dayTime: a.targetDay,
      week: a.targetWeeks,
      value: a.dayWeight,
      label: `${moment(a.targetDay)
        .format('MM-DD')
        .split('-')
        .join('月')}日(${a.targetWeeks})`,
    })
  })
}

const reCalculateData = reactive({
  reCalculateMonth: moment(new Date()).format('YYYY-MM'),
})
const reCalculateVisiable = ref(false) //重新计算弹出框
/**
 * 重新计算
 */
const reCalculate = async () => {
  let param = {
    targetMonth: reCalculateData.reCalculateMonth,
  }
  let res = await API.dayRecalculate(param)
  if (res.code == 200) {
    ElMessage.success('操作成功')
    reCalculateVisiable.value = false
  } else {
    ElMessage.warning(res.msg)
  }
}

/**
 * 导出
 */
const exportData = async event => {
  let model = {
    regions: searchFilter.areaCode,
    stores: searchFilter.storeCodeList,
    targetDay: searchFilter.targetDay,
    pageNum: searchFilter.pageNum,
    pageSize: searchFilter.pageSize,
  }
  fullscreenLoading.value = true
  createMoveBall(event)
  const param = generateParam(model)
  let res = await post(`/shadow/export/download?type=STORE_REVENUE_DAILY_REPORT`, param).finally(() => {
    fullscreenLoading.value = false
  })
  if (res.code == 200) {
    ElMessage.success('导出成功')
  } else {
    ElMessage.error('导出失败')
  }
}

const updateFormRef = ref(null)

const validateStoreCodeList = (rule, value, callback) => {
  if (updateRowMsg.value.storeCodeList.length === 0) {
    return callback(new Error('请至少选择一家门店'))
  }
  callback()
}
const updateRuleForm = reactive({
  targetMonth: [{ required: true, message: '请选择指标月份', trigger: 'blur' }],
  storeCodeList: [{ required: true, validator: validateStoreCodeList, trigger: 'blur' }],
})
/**
 * 修改某一行数据
 * @param {object} row
 */

const updateTarget = async elform => {
  if (!elform) return
  await elform.validate(async (valid, fields) => {
    if (valid) {
      let storeWeightBOList = dayWeekList.value.map(item => ({
        targetDay: item.dayTime,
        targetWeeks: item.week,
        dayWeight: item.value,
      }))

      let hasEmpty = storeWeightBOList.find(item => item.dayWeight == undefined)
      if (hasEmpty) {
        ElMessage.info(`请填写${hasEmpty.targetDay}权重`)
        return
      } else {
        let res = await API.updateStoreDailyWeight({
          targetMonth: updateRowMsg.value.targetMonth,
          storeWeightBOList,
          storeCodeList: updateRowMsg.value.storeCodeList,
        })
        if (res.code == 200) {
          ElMessage.success('编辑成功')
          updateDialogVisible.value = false
        } else {
          ElMessage.warning(res.msg)
        }
      }
    } else {
      ElMessage.info('请填写完成必要信息')
    }
  })
}

/**
 * 改变门店
 * @param {*} data
 */
const changeStore = ({ isAll, data = [], isOneClick }) => {
  if (isOneClick) {
    updateRowMsg.value.storeCodeList = data
    if (data.length > 0) {
      nextTick(() => {
        updateFormRef.value.clearValidate('storeCodeList')
      })
    }

    return
  }
  if (isAll) {
    data.forEach(item => {
      let index = updateRowMsg.value.storeCodeList.findIndex(i => i === item)
      if (index === -1) {
        updateRowMsg.value.storeCodeList.push(item)
      }
    })
  } else {
    data.forEach(item => {
      let index = updateRowMsg.value.storeCodeList.findIndex(i => i === item)
      updateRowMsg.value.storeCodeList.splice(index, 1)
    })
  }

  nextTick(() => {
    updateFormRef.value.clearValidate('storeCodeList')
  })
}

//分页
const handleSizeChange = val => {
  searchFilter.pageSize = val
  queryLists()
}
const handleCurrentChange = val => {
  searchFilter.pageNum = val
  queryLists()
}
</script>

<style scoped lang="scss">
.el-pagination {
  text-align: right;
  margin-right: 2%;
}
.dialog-form {
  ::v-deep .el-form-item__label {
    width: 150px !important;
  }
}
::v-deep .el-cascader {
  width: 280px;
  .el-tag--light {
    max-width: 150px;
  }
}
::v-deep .el-select-v2 {
  .el-tag--light {
    display: flex;
    align-items: center;
    max-width: 120px;
    span {
      display: block;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
    }
  }
}
.first-form {
  ::v-deep .el-form-item {
    margin-bottom: 0px !important;

    .el-form-item__content {
      margin-bottom: 0px !important;
    }
  }
}

.el-row {
  margin-bottom: 10px;
}
.dialog-footer {
  text-align: right;
  display: block;
}
</style>
